$font_size: 40px;
$font-family-sans-serif: Arial;
$color-highlight: #EC672F;

  
  /* Words */
  .word {
    margin-bottom: 4px;
    .txtword, .accuracy, .process {
      font-size: 10px;
      text-align: left;
      font-weight: normal;
    }
    .brword {
      text-align: center;
      font-size: $font_size;
//       margin-bottom: 4px;
      border-radius: 4px;
      
    }
    display: inline-block;
    margin-right: 5px;
    
    &.good {
      .brword {
        border: none;
      }
      .txtword, .accuracy, .process {
        color: black;
      }
    }
    &.bad {
      .brword {
        background-color: $color-highlight;
        div {
          background-color: $color-highlight;
        }
      }
      .txtword, .accuracy {
        color: $color-highlight;
      }
    }
    &.processing {
      .brword {
        background-color: $color-highlight;
      }
      .txtword, .accuracy, .process{
        color: $color-highlight;
      }
    }
    .wrapper {
      > div > div > div {
        padding: 0;
        
        &:first-child div {
          padding: 0;
          margin: 0;
        }
      }

      .suggestion{
        input{
          color: white !important;
          padding: 0 4px !important;
        }
        div[class*="-control"]{
          border: none;
          background: #ECECEC;
          box-shadow: none !important;
        }
        div[class*="-singleValue"]{
          color: black;
        }
        &.focus div[class*="-control"]{
          background: $color-highlight;
          div[class*="-singleValue"]{
            color: white;
          }
        }
        
        div[class*="-menu"]{
          white-space: nowrap;
          width: auto;
        }
        div[class*="-option"]{
          padding: 4px !important;
          cursor: pointer;  
          background: black;
          color: white;
        }
        div[class*="-option"]:hover,
        div[class*="-option"]:active{
          background: $color-highlight;
          color: white;
        }
      }
    }
  }
  .dev-true .word{
    margin-bottom: 0;
  }
  
  .themed.light {
//     background: white;
  }
  
  html {
    height: 100%;
    background: white !important;
    overflow: hidden;
  }
  
  body {
    height: 100%;
    background: white !important;
    overflow: hidden;
    font-family: "Helvetica Neue", Helvetica, sans-serif !important;
    font-size: 18px;
    color: black !important;
    -webkit-overflow-scrolling: touch;
  }
  
  html, textarea, input, .MuiButton-root-11 {
    font-family: "Helvetica Neue", Helvetica, sans-serif !important;
    font-size: 18px;
  }
  textarea {
    font-size: $font_size;
    letter-spacing: -0.0078em;
    background: transparent;
    &:disabled{
      opacity: 1 !important;
    }
  }
  .btn{
    font-family: "Helvetica Neue", Helvetica, sans-serif !important;
  }
  
  #root {
    height: 100%;
    .container-fluid {
      height: 100%;
    }
  }
  
  .hide-on-debug {
    display: none;
  }
  
  .inputBox{
    border-radius: 0;
    width: 100%;
    height: 100%;
    padding: 15px;
    padding-bottom: 60px;
    border: none;
    resize: none;
    font-size: $font_size;
    transition: color .4s ease-out;
    webkit-user-select: text;
    user-select: text;
    line-height: 46px;
  }
  .translationOutputContainer{  
    width: 100%;
    box-sizing: border-box;         /* For IE and modern versions of Chrome */
    -moz-box-sizing: border-box;    /* For Firefox                          */
    -webkit-box-sizing: border-box; /* For Safari    */
  }
  .translationOutputArea{
    border: none;
    width: 100%;
    height: 100%;
    background-color: transparent;
    box-sizing: border-box;         /* For IE and modern versions of Chrome */
    -moz-box-sizing: border-box;    /* For Firefox                          */
    -webkit-box-sizing: border-box; /* For Safari    */
    
    resize: none;
    outline: none;
    color: black;
    line-height: 46px;
    letter-spacing: -0.0078em;
  }

  
  input:focus, select:focus, textarea:focus, button:focus {
    outline: none !important;
  }
  
  textarea {
    caret-color: $color-highlight;
    color: black;
  }
  
  ::selection {
    background: $color-highlight;
    /* WebKit/Blink Browsers */
    color: white;
  }
  
  ::-moz-selection {
    background: $color-highlight;
    /* Gecko Browsers */
    color: white;
  }
  
  .loading-bar{
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background: $color-highlight;
    width: 0;
    opacity: 0;
    transition: .3s opacity ease-out, 2s width ease-out;
    &.show{
      opacity: 1;
    }
    &.load {
      width: 100%;
    }
  }
  
  .translation {
    width: 100%;
    height: 100%;
    font-size: $font_size;
    padding: 15px;
    padding-bottom: 60px;
    transition: color .4s ease-out, background .4s ease-out;
    position: relative;
    overflow: scroll;
  }
  
  .output,
  .input-types {
    position: relative;
    border: 1px solid white;
    transition: border .4s ease-out;
  }
  
  .translation h4 {
    margin-bottom: 40px;
    font-size: 12px;
  }
  
  #input-output nav {
    z-index: 2;
    transition: opacity .4s ease-out,background .4s ease-out;
    background: white;

    &.hide {
      opacity: 0;
      pointer-events: none;
    }
  }
  
  .row-translation > .col .file-drop > .file-drop-target {
    transform: background .4s ease-out;
  }
  
  .file-drop > .file-drop-target {
    &.file-drop-dragging-over-target {
      box-shadow: none !important;
      &:after{
        outline: 2px solid $color-highlight;
        outline-offset: 0px;
      }
    }
    &:after {
      content: "Drag TXT file here.";
      color: $color-highlight;
      font-size: 40px;
      padding: 15px;
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 1);
      pointer-events: none;
      opacity: 0;
      transition: opacity .3s ease-in-out;
      text-align: left;
    }
    &.file-drop-dragging-over-frame:after, &.file-drop-dragging-over-target:after {
      opacity: 1;
    }
  }
  
  .language-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      list-style: none;
      font-size: 18px;
    }
  }
  
  .input-window {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity .3s ease-in-out, background .4s ease-out;
    z-index: 1;
    .content {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      opacity: 0;
      transition: opacity .3s ease-in-out, background .4s ease-out;
      z-index: 1;
      color: $color-highlight;
      font-size: 40px;
      padding: 15px;
      background: rgba(255, 255, 255, 1);
      text-align: left;
    }
  }
  
  .output-window {
    color: $color-highlight;
    font-size: 40px;
    padding: 15px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 1);
    pointer-events: none;
    opacity: 0;
    transition: opacity .3s ease-in-out, background .4s ease-out;
    text-align: left;
    z-index: 1;
  }
  
  .input-window {
    .content.show {
      opacity: 1;
    }
    &.show {
      &#input-windows-speech{
//         outline: 2px solid rgba(236, 103, 47, 1);
//         outline-offset: -1px;
      }
      opacity: 1;
      .content{
        outline: 2px solid rgba(236, 103, 47, 1);
        outline-offset: -1px;
      }
    }
  }
  
  .output-window.show {
    outline: 2px solid rgba(236, 103, 47, 1);
    outline-offset: -1px;
    opacity: 1;
  }
  
  .row-top .grid-center, .row-main .grid-center, .row-bottom .grid-center {
    border-left: 1px solid black;
    border-right: 1px solid black;
  }
  
  .row-main {
    .grid {
      border-top: 1px solid black;
      border-bottom: 1px solid black;
    }
    .row-navigation {
      border-bottom: 1px solid black;
    }
  }
  
  .grid.grid-side.col {
    flex: 0 0 120px;
  }
  
  .row-bottom, .row-top {
    height: 10px;
  }
  
  .row-main {
    height: calc(100% - 20px);
  }
  
  .row-translation .col-left {
    border-right: 1px solid black;
  }
  
  .row-navigation > .col {
    height: 110px;
  }
  
  .logo {
    width: 83px;
    position: absolute;
    .white, .black {
      position: absolute;
      left: 0;
      bottom: 0;
      transition: opacity .4s ease-out;
    }
    .white {
      opacity: 0;
    }
  }
  
  #logo-1 {
    bottom: 13px;
    left: 17px;
  }
  
  #logo-2 {
    left: 0;
    top: 38px;
    opacity: 0;
    transition: opacity .4s ease-in-out;
    z-index: 3;
  }
  
  #wrapper {
    &.menuOpen {
      #logo-2 {
        opacity: 1;
      }
      transform: translate3d(230px, 0, 0);
    }
    height: 100%;
    transform: translate3d(0, 0, 0);
    transition: transform .4s ease-in-out;
  }
  
  #input-output {
    height: calc(100vh - 133px);
    div {
      word-break: break-word;
    }
    .col-left {
      height: 100%;
    }
    .col-right {
      height: 100%;
      > div {
        height: 100%;
        > div {
          height: 100%;
        }
      }
    }
    .col-left {
      > div {
        height: 100%;
        > div, textarea {
          height: 100%;
        }
      }
      #react-file-drop-demo {
        height: 100%;
      }
      .input-types > div {
        height: 100%;
        > div {
          height: 100%;
        }
      }
    }
  }
  
  .file-drop {
    height: 100%;
  }
  
  #input-output.dev-true .translation .hide-on-debug {
    display: block;
  }
  
  #wrapper {
    #menu nav {
      width: calc(100% - 2px);
      bottom: 1px;
      padding: 0;
      left: 1px;
      text-align: left;
      z-index: 3;
    }
    &.menuOpen #menu nav {}
    #menu .menu-wrapper {
      position: absolute;
      width: 350px;
      height: calc(100% + 2px);
      border-bottom: 1px solid black;
      border-top: 1px solid black;
      top: -1px;
      left: 0;
      z-index: 1;
      /*   transition: transform .4s ease-in-out; */
      pointer-events: none;
      transform: translate3d(-230px, 0, 0);
      overflow: hidden;
    }
    &.menuOpen #menu .menu-wrapper {
      pointer-events: all;
    }
    #menu .menu-wrapper:after {
      content: "";
      position: absolute;
      width: 50%;
      height: 100%;
      top: 0;
      left: calc(50% - 1px);
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
      background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
      /* IE6-9 */
      z-index: 2;
      pointer-events: none;
      opacity: 1;
      transition: opacity .3s ease-in-out;
    }
    &.menuOpen #menu .menu-wrapper:after {
//       transition: opacity .8s ease-in-out;
      opacity: 0;
    }
    #menu .menu-wrapper .menu-content {
      transition: transform .4s ease-in-out, opacity .4s ease-in-out;
      transform: translate3d(150px, 0, 0);
      opacity: 0;
      top: 155px;
      position: absolute;
      width: 330px;
    }
    &.menuOpen #menu .menu-wrapper .menu-content {
      transform: translate3d(0px, 0, 0);
      opacity: 1;
    }
  }
  
  .btn-bookmark {
    width: 100%;
    padding-left: 16px;
    font-weight: 600;
    font-size: 30px;
    color: black;
    transition: color .4s ease-out, background .4s ease-out;
    &:hover {
      color: black;
    }
  }
  
  #wrapper {
    #menu {
      cursor: pointer;
      nav {
        .btn span {
          font-size: 18px;
        }
        &.meta {
          font-size: 14px;
          line-height: 22px;
          transition: transform .4s ease-in-out, opacity .4s ease-in-out;
          opacity: 0;
          transform: translate3d(0px, 0, 0);
          pointer-events: none;
          .btn {
            font-size: 14px;
          }
        }
      }
    }
    &.menuOpen #menu nav.meta {
      opacity: 1;
      transform: translate3d(0px, 0, 0);
      pointer-events: all;
    }
    #menu nav.meta {
      .btn {
        padding: 0 15px;
        color: rgba(0, 0, 0, 0.5);
      }
      p {
        padding: 0 15px;
        color: rgba(0, 0, 0, 0.5);
        transition: opacity .4s ease-in-out;
      }
    }
  }
  
  #preloader div {
    height: 10px;
  }
  
  #input-output .col {
    padding: 0;
  }
  
  nav {
    position: absolute;
    bottom: 1px;
    padding: 0;
    left: 1px;
    width: calc(100% - 2px);
  }
  
  button {
    background: white !important;
    color: black !important;
    box-shadow: none !important;
    padding-bottom: 15px !important;
    text-transform: none !important;
    font-weight: 400 !important;
    transition: color .4s ease-out, background .4s ease-out !important;
    &.output-window-button {
      &.show {
        color: $color-highlight !important;
        font-weight: 500 !important;
      }
    }
  }
  
  #input-output .btn {
    font-size: 18px;
    span {
      background: transparent !important;
    }
  }
  
  #menu > nav > .btn span:first-child {
    background: transparent !important;
  }
  
  #input-output {
    .btn {
      &:after {
        content: "";
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        right: -2px;
        width: 2px;
        height: 2px;
        border-radius: 6px;
      }
      &:last-child:after {
        content: none;
      }
      background: transparent !important;
    }
    .col-left .btn {
      color: rgba(0, 0, 0, 0.5) !important;
    }
    .btn.active {
      color: rgba(0, 0, 0, 1) !important;
      font-weight: 500 !important;
    } 
    .btn-edit.active,
    .btn.is-recording {
      font-weight: 500 !important;
      color: rgba(236, 103, 47, 1) !important;
    }
    .btn:hover {
      color: rgba(236, 103, 47, 1) !important;
    }
  }
  
  .btn {
    background: transparent !important;
    padding: 10px 14px !important;
    line-height: .8 !important;
    span {}    
    &.on-mobile {
      display: none;
    }
  }
  .btn.active,
  .btn.show,
  .btn.is-recording {
    padding-bottom: 11px !important;
  }
  #wrapper #menu nav.meta .btn {
    padding: 2px 16px !important;
  }
  
  .col-avatar {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: -25px;
  }
  
  #score {
    /* 	pointer-events: none; */
    opacity: 0.01;
    transition: opacity .5s ease-out;
    z-index: 20;
    width: 140px;
    height: 140px;
    transform: translate(0, 0);
    top: 0;
    left: 0;
    position: relative;
    &.show {
      opacity: 1;
    }
  }
  .__react_component_tooltip{
    padding: 10px 15px;
    &.type-dark{
      background: black;
      &.place-right:after {
        border-right-color: black;
      }
    }
    &.show{
      opacity: 1;
    }
    p{
      margin: 0;
    }

  }
  
  .themed {
    transition: color .4s ease-out, background .4s ease-out;
    &.dark {
      background: rgba(25, 25, 25, 1);
      color: white;
      .btn-bookmark {
        color: white;
        &:hover {
          color: white;
        }
      }
      h1 {
        color: white;
      }
      textarea {
        color: white;
      }
      .output,
      .input-types {
        position: relative;
        border: 1px solid rgba(25, 25, 25, 1);
      }
      #input-output nav {
        background: rgba(25, 25, 25, 1);
      }
      
      .word {
        &.good {
          .txtword, .accuracy, .process {
            color: white;
          }
        }
        &.bad {
          .brword {
            background-color: $color-highlight;
            div {
              background-color: $color-highlight;
            }
          }
          .txtword, .accuracy {
            color: $color-highlight;
          }
        }
        &.processing {
          .brword {
            background-color: $color-highlight;
          }
          .txtword, .accuracy, .process {
            color: $color-highlight;
          }
        }
        .wrapper {
          margin-bottom: 4px;
          .suggestion{
            input{
              color: black !important;
            }
            div[class*="-control"]{
              background: #383838;
              color: white;
            }
            div[class*="-singleValue"]{
              color: white;
            }
            &.focus div[class*="-control"]{
              background: $color-highlight;
              div[class*="-singleValue"]{
                color: black;
              }
            }
            
            div[class*="-option"]{
              padding: 4px !important;
              cursor: pointer;  
              background: white;
              color: black;
            }
            div[class*="-option"]:hover,
            div[class*="-option"]:active{
              background: $color-highlight;
              color: black;
            }
          }
        }
      }

      #wrapper #menu nav.meta {
        .btn, p {
          color: rgba(255, 255, 255, 0.5);
        }
      }
      .col, #wrapper #menu .menu-wrapper, .row-navigation, .row-translation .col-left {
        border-color: white;
      }
      button {
        background: transparent !important;
        color: white !important;
      }
      #wrapper #menu .menu-wrapper:after {
        background: -moz-linear-gradient(left, rgba(25, 25, 25, 0) 0%, rgba(25, 25, 25, 1) 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(25, 25, 25, 0) 0%, rgba(25, 25, 25, 1) 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(25, 25, 25, 0) 0%, rgba(25, 25, 25, 1) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00191919', endColorstr='#191919',GradientType=1 );
        /* IE6-9 */
      }

      #input-output {
        .col-left .btn {
          color: rgba(255, 255, 255, 0.5) !important;
        }
        .btn.active {
          color: rgba(255, 255, 255, 1) !important;
        }
        .btn.is-recording,
        .btn:hover {
          color: rgba(236, 103, 47, 1) !important;
        }
      }
      .logo {
        .white {
          opacity: 1;
        }
        .black {
          opacity: 0;
        }
      }
      
      .output-window {
         background: rgba(25, 25, 25, 1);
      }
      .input-window {
        .content {
           background: rgba(25, 25, 25, 1);
        }
      }
      .file-drop > .file-drop-target {
        &:after {
          background: rgba(25, 25, 25, 1);
        }
      }
      .__react_component_tooltip{
        &.type-dark{
          background: white;
          color: black;
          &.place-right:after {
            border-right-color: white;
          }
        }
      }
    }
  }
  

  
  @media only screen and (min-device-width: 768px){
    #input-output .col-left > div > div, #input-output .col-left > div textarea {
      height: 100% !important;
    }
  }
  @media only screen and (max-device-width: 767px){
    body {
      height: 100%;
      overflow: hidden;
      position: fixed;
      width: 100%;
      &.keyboard-open #root{
//         height: calc(100% - 263px);
      }
    }
    #root {
      height: 100%;
      overflow: hidden;
      position: fixed;
      width: 100%;
    }
    .container-fluid {
      padding: 0 !important;
      position: fixed;
    }
    .row-main .grid-right, .row-top .grid-side, .row-bottom .grid-side {
      display: none;
    }
    .row-top .grid-center, .row-bottom .grid-center, .row-top .grid-side, .row-main .grid-side, .row-bottom .grid-side {
      border: none;
    }
    .row-main .grid {
      border-top: none;
      border-bottom: none;
    }
    .row-main .grid-center {
      border-right: none;
      border-left: none;
    }
    .grid.grid-side.col {
      /* 	  flex: 0 0 0; */
      padding: 0;
    }
    textarea {
      letter-spacing: -0.0045em;
    }
    #logo-2 {
      right: 20px;
      left: auto;
      top: auto;
      bottom: 50px;
    }
    .col-avatar {
      pointer-events: none;
      z-index: 9;
      top: -5px;
    }
    #score {
      width: 90px;
      height: 90px;
      top: 0px;
    }
    .btn-upload, .btn-keyboard {
      display: none !important;
    }
    .output-window{
      font-size: 25px;
      height: 100% !important;
    }
    #wrapper #menu .menu-wrapper:after,
    .input-types:after, .output:after {
      content: normal;
    }
    #input-output {
      #react-file-drop-demo{
        z-index: 3;
        position: relative;
        padding-top: 40px;
        pointer-events: none;
        textarea{
          background: white;
          pointer-events: all;
          transition: background .4s ease-out, height .4s ease-in-out,max-height .4s ease-in-out;
        }
      }
      nav {
        bottom: auto;
        top: 0;
        z-index: 1;
        transition: transform .4s ease-in-out;
      }
      .btn {
        min-width: auto;
        letter-spacing: -0.0018em;
        &:nth-child(1):after, &:nth-child(2):after, &:nth-child(3):after {
          content: normal;
        }
        &.on-mobile {
          display: block;
        }
        &.btn-delete {
          position: absolute;
          right: 0;
          top: -1px;
          z-index: 2;
          color: black !important;
          transition: transform .4s ease-in-out, opacity .4s ease-in-out;
          &.hide{
            opacity: 0;
            pointer-events: none;
          }
        }
      }
      .translation {
        padding: 33px 0;
      }
    }
  
    .btn.active,
    .btn.show,
    .btn.is-recording {
      padding-top: 14px !important;
      padding-bottom: 16px !important;
    }
    
    #menu > nav .btn {
      display: inline-block;
      width: 25px;
      cursor: pointer;
      text-decoration: none;
      height: 72px;
      position: fixed;
      top: 0;
      left: 0;
      
      span {
        background: black;
        overflow: visible;
        border-radius: 0;
        &:first-child, span {
          display: none;
        }
        position: relative;
        display: inline-block;
        width: 20px;
        height: 2px;
        background: black;
        transition: all .2s ease-out;
        &:before, &:after {
          content: '';
          width: 20px;
          height: 2px;
          background: black;
          position: absolute;
          left: 0;
          transition: all .2s ease-out;
        }
        &:before {
          top: -8px;
        }
        &:after {
          bottom: -8px;
        }
      }
    }
    #wrapper.menuOpen #menu > nav .btn span {
      background: transparent;
      &:before {
        top: 0;
        transform: rotateZ(45deg);
      }
      &:after {
        bottom: 0;
        transform: rotateZ(-45deg);
      }
    }
    .row-main {
      display: block;
      .row-navigation:before {
        content: "";
        position: relative;
        height: 70px;
        display: block;
        width: 100%;
        border-bottom: 1px solid black;
        background: white;
        transition: background .4s ease-out, opacity .2s .2s ease-in-out, transform .4s ease-in-out;
        
        z-index:8;
      }
    }
    .row-navigation > .col {
      height: 60px;
      border-bottom: 1px solid black;
      background: white;
    }
    .row-main .row-navigation {
      border-bottom: none;
      position: fixed;
      top: 0;
      width: calc(100% + 30px);
      z-index: 10;
      height: 0;
    }
    .row-top {
      height: 10px;
      z-index: 13;
      position: relative;
      border-bottom: 1px solid;
      background: white;
      transition: background .4s ease-out;
    }
    input[type="checkbox"].switch + div {
      right: 15px !important;
    }
    label {
      margin-bottom: 0 !important;
    }
    #wrapper {
      transition: none;
      transform: translate3d(0, 0, 0);
      #menu {
        display: block;
        position: absolute;
        height: 100%;
        left: 0;
        nav {
          bottom: 24px;
          z-index: 12;
        }
        .menu-wrapper {
          width: 100%;
          transform: translate3d(-100%, 0, 0);
          border-bottom: none;
          border-top: none;
          height: 100%;
          transition: transform .4s ease-in-out;
          .menu-content {
            transform: translate3d(0%, 0, 0);
            width: 100%;
            opacity: 1;
            top: 80px;
            a {
              display: none;
            }
          }
        }
      }
      &.menuOpen {
        transform: translate3d(0, 0, 0);
      }
    }
    .btn {
      padding: 15px 15px !important;
    }
    .language-switch {
      transition: transform .4s ease-in-out;
      li{
        font-size: 14px;
        letter-spacing: -0.0018em;
      }
    }
    #input-output {
      padding: 0 15px;
      padding-top: 120px;
      .col-left {
        border-bottom: 1px solid black;
        border-right: none;
      }
      .btn {
        font-size: 14px;
      }
    }
    .inputBox ,
    .translationOutputArea{
      padding: 3px 15px 15px 15px;
      font-size: 25px;
      line-height: 29px;
    }
    .row-navigation > .col:first-child {
//      border-left: 1px solid black;todo:menudivider
    }
    #input-output, .row-navigation > .col {
      transition: transform .4s ease-in-out;
//       position: relative;
//       z-index: 9;
    }
    #wrapper {

      #menu .menu-wrapper:after {
        content: normal;
      }
      &.menuOpen {
        #input-output {
          transform: translate3d(100%, 0, 0);
//          border-left: 1px solid black;todo:menudivider
        }
        .row-main .row-navigation{
          pointer-events: none;
        }
        .row-navigation > .col {
          transform: translate3d(200%, 0, 0);
        }
        #menu .menu-wrapper {
          transform: translate3d(0%, 0, 0);
        }
      }
    }
    .themed.dark {
      #input-output .col-left, .row-main .row-navigation:before, #input-output, .row-navigation > .col, #wrapper.menuOpen #input-output {
        border-color: white;
      }
      .row-top,
      .row-navigation > .col{
        background: rgba(25, 25, 25, 1); 
      }

      #menu > nav .btn span {
        background: white;
        &:before, &:after {
          background: white;
        }
      }
      #input-output #react-file-drop-demo textarea{
	      background: rgba(25, 25, 25, 1); 
      }
      .row-main .row-navigation:before {
        background: rgba(25, 25, 25, 1);
      }
      #input-output .col-left .btn{
        color: rgba(255, 255, 255, 1) !important;
      }
    }
    
    #input-output {
      min-height: calc(100vh - 22px);
      height: auto;
      display: block;
      div {
        word-break: break-word;
      }
      .col {
        display: block;
        height: auto;
      }
      .col-left {
        height: auto;
        
        > div {
          height: auto;
          > div, textarea {
            height: auto;
          }
        }
        #react-file-drop-demo {
          height: auto;
        }
        .input-types > div {
          height: auto;
          > div {
            height: auto;
          }
        }
      }
      .col-right {
        height: auto;
        > div {
          height: auto;
          > div {
            height: auto;
          }
        }
      }
    }
    .level-0{
      #input-output {
        transition: min-height .4s ease-in-out, transform .4s ease-in-out;
        transform: translate3d(0,0,0);
      }
      #input-output #react-file-drop-demo textarea{
        max-height: calc(100vh - 170px) !important;
      }
      &.input-focus{
        #input-output #react-file-drop-demo textarea{
          max-height: calc(100vh - 423px) !important;
        }
      }
    }

//LEVEL 1
    
    .level-1,
    .level-2,
    .level-3,
    .level-4,
    .level-5{
      .row-main .row-navigation:before {
        transition: background .4s ease-out, opacity .2s  ease-in-out, transform .4s ease-in-out;
        transform: translate3d(0,0,0);
        opacity: 0;
      }
      .row-navigation > .col {
        transform: translate3d(0,-60px,0);
        &:first-child .language-switch {
          transform: translate3d(15%,0,0) scale(.8);
        }
        &:nth-child(2) .language-switch {
          transform: translate3d(0%,0,0) scale(.8);
        }
      }
      
      #input-output {
        transform: translate3d(0,-60px,0);
      }
      
    }
    .level-1{
      #input-output #react-file-drop-demo textarea{
        max-height: calc(100vh - 110px) !important;
      }
      &.input-focus{
        #input-output #react-file-drop-demo textarea{
          max-height: calc(100vh - 363px) !important;
        }
      }
      
    }
    //for menübuttons staying at top when opening menu and move to right.
    .level-1,
    .level-2,
    .level-3,
    .level-4,
    .level-5{
      #wrapper.menuOpen {
        .row-main .row-navigation:before {
          transform: translate3d(0,0,0);
          opacity: 1;
        }
        #input-output {
          transform: translate3d(100%, -60px, 0);
        }
        .row-navigation > .col {
          transform: translate3d(200%, -60px, 0);
        }
      }
    }

//LEVEL 2

    .level-2,
    .level-3,
    .level-4,
    .level-5{
      #input-output {
        transform: translate3d(0, -100px, 0);
      }
      #input-output .btn.btn-delete,
      #input-output nav{
        // transform: translate3d(0,-100px,0);
      }
    } 
    .level-2{
      #input-output #react-file-drop-demo textarea{
        max-height: calc(100vh - 70px) !important;
      }
      &.input-focus{
        #input-output #react-file-drop-demo textarea{
          max-height: calc(100vh - 323px) !important;
        }
      }
    }
    //for menübuttons staying at top when opening menu and move to right.
    .level-2,
    .level-3{
      #wrapper.menuOpen {
        #input-output {
          transform: translate3d(100%, -100px, 0);
        }
      }
    }
    
//LEVEL 3
    .level-3,
    .level-4,
    .level-5{
      #input-output #react-file-drop-demo textarea{
        height: 100px !important; 
      }
      
      .translationOutputArea{
        height: calc(100vh - 210px) !important;
      }
    }
    .level-3{
      &.input-focus{
        .translationOutputArea{
          height: calc(100vh - 463px) !important;
        }
      }
    }
    
//LEVEL 4
    .level-4,
    .level-5{
      #input-output {
        transform: translate3d(0,-203px,0);
      }
      
      .translationOutputArea{
        height: calc(100vh - 110px) !important;
      }
      &.input-focus{
        .translationOutputArea{
          height: calc(100vh - 363px) !important;
        }
      }
    }
    //for menübuttons staying at top when opening menu and move to right.
    .level-4,
    .level-5{
      #wrapper.menuOpen {
        #input-output {
          transform: translate3d(100%, -203px, 0);
        }
      }
    }
  }
  
  @media only screen and (max-device-width: 767px) and (orientation: landscape) {
    #wrapper #menu {
      .menu-wrapper {
        overflow-y: scroll;
        padding-top: 70px;
        .menu-content {
          position: relative;
          top: 0;
        }
      }
      nav.meta {
        position: relative;
        bottom: auto;
        margin-top: 20px;
      }
    }
    #logo-2 {
      bottom: 30px;
    }
  }

