
#preloader{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #0000002a !important;
    color: black;
    
  }
.circ1, .circ2, .circ3, .circ4 {
    background-color: black !important;
}