body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Words */
.word {
  margin-bottom: 4px;
  display: inline-block;
  margin-right: 5px; }
  .word .txtword, .word .accuracy, .word .process {
    font-size: 10px;
    text-align: left;
    font-weight: normal; }
  .word .brword {
    text-align: center;
    font-size: 40px;
    border-radius: 4px; }
  .word.good .brword {
    border: none; }
  .word.good .txtword, .word.good .accuracy, .word.good .process {
    color: black; }
  .word.bad .brword {
    background-color: #EC672F; }
    .word.bad .brword div {
      background-color: #EC672F; }
  .word.bad .txtword, .word.bad .accuracy {
    color: #EC672F; }
  .word.processing .brword {
    background-color: #EC672F; }
  .word.processing .txtword, .word.processing .accuracy, .word.processing .process {
    color: #EC672F; }
  .word .wrapper > div > div > div {
    padding: 0; }
    .word .wrapper > div > div > div:first-child div {
      padding: 0;
      margin: 0; }
  .word .wrapper .suggestion input {
    color: white !important;
    padding: 0 4px !important; }
  .word .wrapper .suggestion div[class*="-control"] {
    border: none;
    background: #ECECEC;
    box-shadow: none !important; }
  .word .wrapper .suggestion div[class*="-singleValue"] {
    color: black; }
  .word .wrapper .suggestion.focus div[class*="-control"] {
    background: #EC672F; }
    .word .wrapper .suggestion.focus div[class*="-control"] div[class*="-singleValue"] {
      color: white; }
  .word .wrapper .suggestion div[class*="-menu"] {
    white-space: nowrap;
    width: auto; }
  .word .wrapper .suggestion div[class*="-option"] {
    padding: 4px !important;
    cursor: pointer;
    background: black;
    color: white; }
  .word .wrapper .suggestion div[class*="-option"]:hover,
  .word .wrapper .suggestion div[class*="-option"]:active {
    background: #EC672F;
    color: white; }

.dev-true .word {
  margin-bottom: 0; }

html {
  height: 100%;
  background: white !important;
  overflow: hidden; }

body {
  height: 100%;
  background: white !important;
  overflow: hidden;
  font-family: "Helvetica Neue", Helvetica, sans-serif !important;
  font-size: 18px;
  color: black !important;
  -webkit-overflow-scrolling: touch; }

html, textarea, input, .MuiButton-root-11 {
  font-family: "Helvetica Neue", Helvetica, sans-serif !important;
  font-size: 18px; }

textarea {
  font-size: 40px;
  letter-spacing: -0.0078em;
  background: transparent; }
  textarea:disabled {
    opacity: 1 !important; }

.btn {
  font-family: "Helvetica Neue", Helvetica, sans-serif !important; }

#root {
  height: 100%; }
  #root .container-fluid {
    height: 100%; }

.hide-on-debug {
  display: none; }

.inputBox {
  border-radius: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  padding-bottom: 60px;
  border: none;
  resize: none;
  font-size: 40px;
  transition: color .4s ease-out;
  webkit-user-select: text;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
  line-height: 46px; }

.translationOutputContainer {
  width: 100%;
  box-sizing: border-box;
  /* For IE and modern versions of Chrome */
  -moz-box-sizing: border-box;
  /* For Firefox                          */
  -webkit-box-sizing: border-box;
  /* For Safari    */ }

.translationOutputArea {
  border: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
  box-sizing: border-box;
  /* For IE and modern versions of Chrome */
  -moz-box-sizing: border-box;
  /* For Firefox                          */
  -webkit-box-sizing: border-box;
  /* For Safari    */
  resize: none;
  outline: none;
  color: black;
  line-height: 46px;
  letter-spacing: -0.0078em; }

input:focus, select:focus, textarea:focus, button:focus {
  outline: none !important; }

textarea {
  caret-color: #EC672F;
  color: black; }

::-moz-selection {
  background: #EC672F;
  /* WebKit/Blink Browsers */
  color: white; }

::selection {
  background: #EC672F;
  /* WebKit/Blink Browsers */
  color: white; }

::-moz-selection {
  background: #EC672F;
  /* Gecko Browsers */
  color: white; }

.loading-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background: #EC672F;
  width: 0;
  opacity: 0;
  transition: .3s opacity ease-out, 2s width ease-out; }
  .loading-bar.show {
    opacity: 1; }
  .loading-bar.load {
    width: 100%; }

.translation {
  width: 100%;
  height: 100%;
  font-size: 40px;
  padding: 15px;
  padding-bottom: 60px;
  transition: color .4s ease-out, background .4s ease-out;
  position: relative;
  overflow: scroll; }

.output,
.input-types {
  position: relative;
  border: 1px solid white;
  transition: border .4s ease-out; }

.translation h4 {
  margin-bottom: 40px;
  font-size: 12px; }

#input-output nav {
  z-index: 2;
  transition: opacity .4s ease-out,background .4s ease-out;
  background: white; }
  #input-output nav.hide {
    opacity: 0;
    pointer-events: none; }

.row-translation > .col .file-drop > .file-drop-target {
  -webkit-transform: background .4s ease-out;
          transform: background .4s ease-out; }

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  box-shadow: none !important; }
  .file-drop > .file-drop-target.file-drop-dragging-over-target:after {
    outline: 2px solid #EC672F;
    outline-offset: 0px; }

.file-drop > .file-drop-target:after {
  content: "Drag TXT file here.";
  color: #EC672F;
  font-size: 40px;
  padding: 15px;
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  pointer-events: none;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  text-align: left; }

.file-drop > .file-drop-target.file-drop-dragging-over-frame:after, .file-drop > .file-drop-target.file-drop-dragging-over-target:after {
  opacity: 1; }

.language-switch {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100%; }
  .language-switch ul {
    margin: 0;
    padding: 0; }
  .language-switch li {
    list-style: none;
    font-size: 18px; }

.input-window {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity .3s ease-in-out, background .4s ease-out;
  z-index: 1; }
  .input-window .content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity .3s ease-in-out, background .4s ease-out;
    z-index: 1;
    color: #EC672F;
    font-size: 40px;
    padding: 15px;
    background: white;
    text-align: left; }

.output-window {
  color: #EC672F;
  font-size: 40px;
  padding: 15px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  pointer-events: none;
  opacity: 0;
  transition: opacity .3s ease-in-out, background .4s ease-out;
  text-align: left;
  z-index: 1; }

.input-window .content.show {
  opacity: 1; }

.input-window.show {
  opacity: 1; }
  .input-window.show .content {
    outline: 2px solid #ec672f;
    outline-offset: -1px; }

.output-window.show {
  outline: 2px solid #ec672f;
  outline-offset: -1px;
  opacity: 1; }

.row-top .grid-center, .row-main .grid-center, .row-bottom .grid-center {
  border-left: 1px solid black;
  border-right: 1px solid black; }

.row-main .grid {
  border-top: 1px solid black;
  border-bottom: 1px solid black; }

.row-main .row-navigation {
  border-bottom: 1px solid black; }

.grid.grid-side.col {
  -webkit-flex: 0 0 120px;
          flex: 0 0 120px; }

.row-bottom, .row-top {
  height: 10px; }

.row-main {
  height: calc(100% - 20px); }

.row-translation .col-left {
  border-right: 1px solid black; }

.row-navigation > .col {
  height: 110px; }

.logo {
  width: 83px;
  position: absolute; }
  .logo .white, .logo .black {
    position: absolute;
    left: 0;
    bottom: 0;
    transition: opacity .4s ease-out; }
  .logo .white {
    opacity: 0; }

#logo-1 {
  bottom: 13px;
  left: 17px; }

#logo-2 {
  left: 0;
  top: 38px;
  opacity: 0;
  transition: opacity .4s ease-in-out;
  z-index: 3; }

#wrapper {
  height: 100%;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out; }
  #wrapper.menuOpen {
    -webkit-transform: translate3d(230px, 0, 0);
            transform: translate3d(230px, 0, 0); }
    #wrapper.menuOpen #logo-2 {
      opacity: 1; }

#input-output {
  height: calc(100vh - 133px); }
  #input-output div {
    word-break: break-word; }
  #input-output .col-left {
    height: 100%; }
  #input-output .col-right {
    height: 100%; }
    #input-output .col-right > div {
      height: 100%; }
      #input-output .col-right > div > div {
        height: 100%; }
  #input-output .col-left > div {
    height: 100%; }
    #input-output .col-left > div > div, #input-output .col-left > div textarea {
      height: 100%; }
  #input-output .col-left #react-file-drop-demo {
    height: 100%; }
  #input-output .col-left .input-types > div {
    height: 100%; }
    #input-output .col-left .input-types > div > div {
      height: 100%; }

.file-drop {
  height: 100%; }

#input-output.dev-true .translation .hide-on-debug {
  display: block; }

#wrapper #menu nav {
  width: calc(100% - 2px);
  bottom: 1px;
  padding: 0;
  left: 1px;
  text-align: left;
  z-index: 3; }

#wrapper #menu .menu-wrapper {
  position: absolute;
  width: 350px;
  height: calc(100% + 2px);
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  top: -1px;
  left: 0;
  z-index: 1;
  /*   transition: transform .4s ease-in-out; */
  pointer-events: none;
  -webkit-transform: translate3d(-230px, 0, 0);
          transform: translate3d(-230px, 0, 0);
  overflow: hidden; }

#wrapper.menuOpen #menu .menu-wrapper {
  pointer-events: all; }

#wrapper #menu .menu-wrapper:after {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: calc(50% - 1px);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
  /* IE6-9 */
  z-index: 2;
  pointer-events: none;
  opacity: 1;
  transition: opacity .3s ease-in-out; }

#wrapper.menuOpen #menu .menu-wrapper:after {
  opacity: 0; }

#wrapper #menu .menu-wrapper .menu-content {
  transition: opacity .4s ease-in-out, -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out, opacity .4s ease-in-out;
  transition: transform .4s ease-in-out, opacity .4s ease-in-out, -webkit-transform .4s ease-in-out;
  -webkit-transform: translate3d(150px, 0, 0);
          transform: translate3d(150px, 0, 0);
  opacity: 0;
  top: 155px;
  position: absolute;
  width: 330px; }

#wrapper.menuOpen #menu .menu-wrapper .menu-content {
  -webkit-transform: translate3d(0px, 0, 0);
          transform: translate3d(0px, 0, 0);
  opacity: 1; }

.btn-bookmark {
  width: 100%;
  padding-left: 16px;
  font-weight: 600;
  font-size: 30px;
  color: black;
  transition: color .4s ease-out, background .4s ease-out; }
  .btn-bookmark:hover {
    color: black; }

#wrapper #menu {
  cursor: pointer; }
  #wrapper #menu nav .btn span {
    font-size: 18px; }
  #wrapper #menu nav.meta {
    font-size: 14px;
    line-height: 22px;
    transition: opacity .4s ease-in-out, -webkit-transform .4s ease-in-out;
    transition: transform .4s ease-in-out, opacity .4s ease-in-out;
    transition: transform .4s ease-in-out, opacity .4s ease-in-out, -webkit-transform .4s ease-in-out;
    opacity: 0;
    -webkit-transform: translate3d(0px, 0, 0);
            transform: translate3d(0px, 0, 0);
    pointer-events: none; }
    #wrapper #menu nav.meta .btn {
      font-size: 14px; }

#wrapper.menuOpen #menu nav.meta {
  opacity: 1;
  -webkit-transform: translate3d(0px, 0, 0);
          transform: translate3d(0px, 0, 0);
  pointer-events: all; }

#wrapper #menu nav.meta .btn {
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.5); }

#wrapper #menu nav.meta p {
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.5);
  transition: opacity .4s ease-in-out; }

#preloader div {
  height: 10px; }

#input-output .col {
  padding: 0; }

nav {
  position: absolute;
  bottom: 1px;
  padding: 0;
  left: 1px;
  width: calc(100% - 2px); }

button {
  background: white !important;
  color: black !important;
  box-shadow: none !important;
  padding-bottom: 15px !important;
  text-transform: none !important;
  font-weight: 400 !important;
  transition: color .4s ease-out, background .4s ease-out !important; }
  button.output-window-button.show {
    color: #EC672F !important;
    font-weight: 500 !important; }

#input-output .btn {
  font-size: 18px; }
  #input-output .btn span {
    background: transparent !important; }

#menu > nav > .btn span:first-child {
  background: transparent !important; }

#input-output .btn {
  background: transparent !important; }
  #input-output .btn:after {
    content: "";
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    right: -2px;
    width: 2px;
    height: 2px;
    border-radius: 6px; }
  #input-output .btn:last-child:after {
    content: none; }

#input-output .col-left .btn {
  color: rgba(0, 0, 0, 0.5) !important; }

#input-output .btn.active {
  color: black !important;
  font-weight: 500 !important; }

#input-output .btn-edit.active,
#input-output .btn.is-recording {
  font-weight: 500 !important;
  color: #ec672f !important; }

#input-output .btn:hover {
  color: #ec672f !important; }

.btn {
  background: transparent !important;
  padding: 10px 14px !important;
  line-height: .8 !important; }
  .btn.on-mobile {
    display: none; }

.btn.active,
.btn.show,
.btn.is-recording {
  padding-bottom: 11px !important; }

#wrapper #menu nav.meta .btn {
  padding: 2px 16px !important; }

.col-avatar {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  top: -25px; }

#score {
  /* 	pointer-events: none; */
  opacity: 0.01;
  transition: opacity .5s ease-out;
  z-index: 20;
  width: 140px;
  height: 140px;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  top: 0;
  left: 0;
  position: relative; }
  #score.show {
    opacity: 1; }

.__react_component_tooltip {
  padding: 10px 15px; }
  .__react_component_tooltip.type-dark {
    background: black; }
    .__react_component_tooltip.type-dark.place-right:after {
      border-right-color: black; }
  .__react_component_tooltip.show {
    opacity: 1; }
  .__react_component_tooltip p {
    margin: 0; }

.themed {
  transition: color .4s ease-out, background .4s ease-out; }
  .themed.dark {
    background: #191919;
    color: white; }
    .themed.dark .btn-bookmark {
      color: white; }
      .themed.dark .btn-bookmark:hover {
        color: white; }
    .themed.dark h1 {
      color: white; }
    .themed.dark textarea {
      color: white; }
    .themed.dark .output,
    .themed.dark .input-types {
      position: relative;
      border: 1px solid #191919; }
    .themed.dark #input-output nav {
      background: #191919; }
    .themed.dark .word.good .txtword, .themed.dark .word.good .accuracy, .themed.dark .word.good .process {
      color: white; }
    .themed.dark .word.bad .brword {
      background-color: #EC672F; }
      .themed.dark .word.bad .brword div {
        background-color: #EC672F; }
    .themed.dark .word.bad .txtword, .themed.dark .word.bad .accuracy {
      color: #EC672F; }
    .themed.dark .word.processing .brword {
      background-color: #EC672F; }
    .themed.dark .word.processing .txtword, .themed.dark .word.processing .accuracy, .themed.dark .word.processing .process {
      color: #EC672F; }
    .themed.dark .word .wrapper {
      margin-bottom: 4px; }
      .themed.dark .word .wrapper .suggestion input {
        color: black !important; }
      .themed.dark .word .wrapper .suggestion div[class*="-control"] {
        background: #383838;
        color: white; }
      .themed.dark .word .wrapper .suggestion div[class*="-singleValue"] {
        color: white; }
      .themed.dark .word .wrapper .suggestion.focus div[class*="-control"] {
        background: #EC672F; }
        .themed.dark .word .wrapper .suggestion.focus div[class*="-control"] div[class*="-singleValue"] {
          color: black; }
      .themed.dark .word .wrapper .suggestion div[class*="-option"] {
        padding: 4px !important;
        cursor: pointer;
        background: white;
        color: black; }
      .themed.dark .word .wrapper .suggestion div[class*="-option"]:hover,
      .themed.dark .word .wrapper .suggestion div[class*="-option"]:active {
        background: #EC672F;
        color: black; }
    .themed.dark #wrapper #menu nav.meta .btn, .themed.dark #wrapper #menu nav.meta p {
      color: rgba(255, 255, 255, 0.5); }
    .themed.dark .col, .themed.dark #wrapper #menu .menu-wrapper, .themed.dark .row-navigation, .themed.dark .row-translation .col-left {
      border-color: white; }
    .themed.dark button {
      background: transparent !important;
      color: white !important; }
    .themed.dark #wrapper #menu .menu-wrapper:after {
      /* FF3.6-15 */
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(25, 25, 25, 0) 0%, #191919 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00191919', endColorstr='#191919',GradientType=1 );
      /* IE6-9 */ }
    .themed.dark #input-output .col-left .btn {
      color: rgba(255, 255, 255, 0.5) !important; }
    .themed.dark #input-output .btn.active {
      color: white !important; }
    .themed.dark #input-output .btn.is-recording,
    .themed.dark #input-output .btn:hover {
      color: #ec672f !important; }
    .themed.dark .logo .white {
      opacity: 1; }
    .themed.dark .logo .black {
      opacity: 0; }
    .themed.dark .output-window {
      background: #191919; }
    .themed.dark .input-window .content {
      background: #191919; }
    .themed.dark .file-drop > .file-drop-target:after {
      background: #191919; }
    .themed.dark .__react_component_tooltip.type-dark {
      background: white;
      color: black; }
      .themed.dark .__react_component_tooltip.type-dark.place-right:after {
        border-right-color: white; }

@media only screen and (min-device-width: 768px) {
  #input-output .col-left > div > div, #input-output .col-left > div textarea {
    height: 100% !important; } }

@media only screen and (max-device-width: 767px) {
  body {
    height: 100%;
    overflow: hidden;
    position: fixed;
    width: 100%; }
  #root {
    height: 100%;
    overflow: hidden;
    position: fixed;
    width: 100%; }
  .container-fluid {
    padding: 0 !important;
    position: fixed; }
  .row-main .grid-right, .row-top .grid-side, .row-bottom .grid-side {
    display: none; }
  .row-top .grid-center, .row-bottom .grid-center, .row-top .grid-side, .row-main .grid-side, .row-bottom .grid-side {
    border: none; }
  .row-main .grid {
    border-top: none;
    border-bottom: none; }
  .row-main .grid-center {
    border-right: none;
    border-left: none; }
  .grid.grid-side.col {
    /* 	  flex: 0 0 0; */
    padding: 0; }
  textarea {
    letter-spacing: -0.0045em; }
  #logo-2 {
    right: 20px;
    left: auto;
    top: auto;
    bottom: 50px; }
  .col-avatar {
    pointer-events: none;
    z-index: 9;
    top: -5px; }
  #score {
    width: 90px;
    height: 90px;
    top: 0px; }
  .btn-upload, .btn-keyboard {
    display: none !important; }
  .output-window {
    font-size: 25px;
    height: 100% !important; }
  #wrapper #menu .menu-wrapper:after,
  .input-types:after, .output:after {
    content: normal; }
  #input-output #react-file-drop-demo {
    z-index: 3;
    position: relative;
    padding-top: 40px;
    pointer-events: none; }
    #input-output #react-file-drop-demo textarea {
      background: white;
      pointer-events: all;
      transition: background .4s ease-out, height .4s ease-in-out,max-height .4s ease-in-out; }
  #input-output nav {
    bottom: auto;
    top: 0;
    z-index: 1;
    transition: -webkit-transform .4s ease-in-out;
    transition: transform .4s ease-in-out;
    transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out; }
  #input-output .btn {
    min-width: auto;
    letter-spacing: -0.0018em; }
    #input-output .btn:nth-child(1):after, #input-output .btn:nth-child(2):after, #input-output .btn:nth-child(3):after {
      content: normal; }
    #input-output .btn.on-mobile {
      display: block; }
    #input-output .btn.btn-delete {
      position: absolute;
      right: 0;
      top: -1px;
      z-index: 2;
      color: black !important;
      transition: opacity .4s ease-in-out, -webkit-transform .4s ease-in-out;
      transition: transform .4s ease-in-out, opacity .4s ease-in-out;
      transition: transform .4s ease-in-out, opacity .4s ease-in-out, -webkit-transform .4s ease-in-out; }
      #input-output .btn.btn-delete.hide {
        opacity: 0;
        pointer-events: none; }
  #input-output .translation {
    padding: 33px 0; }
  .btn.active,
  .btn.show,
  .btn.is-recording {
    padding-top: 14px !important;
    padding-bottom: 16px !important; }
  #menu > nav .btn {
    display: inline-block;
    width: 25px;
    cursor: pointer;
    text-decoration: none;
    height: 72px;
    position: fixed;
    top: 0;
    left: 0; }
    #menu > nav .btn span {
      background: black;
      overflow: visible;
      border-radius: 0;
      position: relative;
      display: inline-block;
      width: 20px;
      height: 2px;
      background: black;
      transition: all .2s ease-out; }
      #menu > nav .btn span:first-child, #menu > nav .btn span span {
        display: none; }
      #menu > nav .btn span:before, #menu > nav .btn span:after {
        content: '';
        width: 20px;
        height: 2px;
        background: black;
        position: absolute;
        left: 0;
        transition: all .2s ease-out; }
      #menu > nav .btn span:before {
        top: -8px; }
      #menu > nav .btn span:after {
        bottom: -8px; }
  #wrapper.menuOpen #menu > nav .btn span {
    background: transparent; }
    #wrapper.menuOpen #menu > nav .btn span:before {
      top: 0;
      -webkit-transform: rotateZ(45deg);
              transform: rotateZ(45deg); }
    #wrapper.menuOpen #menu > nav .btn span:after {
      bottom: 0;
      -webkit-transform: rotateZ(-45deg);
              transform: rotateZ(-45deg); }
  .row-main {
    display: block; }
    .row-main .row-navigation:before {
      content: "";
      position: relative;
      height: 70px;
      display: block;
      width: 100%;
      border-bottom: 1px solid black;
      background: white;
      transition: background .4s ease-out, opacity .2s .2s ease-in-out, -webkit-transform .4s ease-in-out;
      transition: background .4s ease-out, opacity .2s .2s ease-in-out, transform .4s ease-in-out;
      transition: background .4s ease-out, opacity .2s .2s ease-in-out, transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
      z-index: 8; }
  .row-navigation > .col {
    height: 60px;
    border-bottom: 1px solid black;
    background: white; }
  .row-main .row-navigation {
    border-bottom: none;
    position: fixed;
    top: 0;
    width: calc(100% + 30px);
    z-index: 10;
    height: 0; }
  .row-top {
    height: 10px;
    z-index: 13;
    position: relative;
    border-bottom: 1px solid;
    background: white;
    transition: background .4s ease-out; }
  input[type="checkbox"].switch + div {
    right: 15px !important; }
  label {
    margin-bottom: 0 !important; }
  #wrapper {
    transition: none;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
    #wrapper #menu {
      display: block;
      position: absolute;
      height: 100%;
      left: 0; }
      #wrapper #menu nav {
        bottom: 24px;
        z-index: 12; }
      #wrapper #menu .menu-wrapper {
        width: 100%;
        -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
        border-bottom: none;
        border-top: none;
        height: 100%;
        transition: -webkit-transform .4s ease-in-out;
        transition: transform .4s ease-in-out;
        transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out; }
        #wrapper #menu .menu-wrapper .menu-content {
          -webkit-transform: translate3d(0%, 0, 0);
                  transform: translate3d(0%, 0, 0);
          width: 100%;
          opacity: 1;
          top: 80px; }
          #wrapper #menu .menu-wrapper .menu-content a {
            display: none; }
    #wrapper.menuOpen {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); }
  .btn {
    padding: 15px 15px !important; }
  .language-switch {
    transition: -webkit-transform .4s ease-in-out;
    transition: transform .4s ease-in-out;
    transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out; }
    .language-switch li {
      font-size: 14px;
      letter-spacing: -0.0018em; }
  #input-output {
    padding: 0 15px;
    padding-top: 120px; }
    #input-output .col-left {
      border-bottom: 1px solid black;
      border-right: none; }
    #input-output .btn {
      font-size: 14px; }
  .inputBox,
  .translationOutputArea {
    padding: 3px 15px 15px 15px;
    font-size: 25px;
    line-height: 29px; }
  #input-output, .row-navigation > .col {
    transition: -webkit-transform .4s ease-in-out;
    transition: transform .4s ease-in-out;
    transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out; }
  #wrapper #menu .menu-wrapper:after {
    content: normal; }
  #wrapper.menuOpen #input-output {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  #wrapper.menuOpen .row-main .row-navigation {
    pointer-events: none; }
  #wrapper.menuOpen .row-navigation > .col {
    -webkit-transform: translate3d(200%, 0, 0);
            transform: translate3d(200%, 0, 0); }
  #wrapper.menuOpen #menu .menu-wrapper {
    -webkit-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0); }
  .themed.dark #input-output .col-left, .themed.dark .row-main .row-navigation:before, .themed.dark #input-output, .themed.dark .row-navigation > .col, .themed.dark #wrapper.menuOpen #input-output {
    border-color: white; }
  .themed.dark .row-top,
  .themed.dark .row-navigation > .col {
    background: #191919; }
  .themed.dark #menu > nav .btn span {
    background: white; }
    .themed.dark #menu > nav .btn span:before, .themed.dark #menu > nav .btn span:after {
      background: white; }
  .themed.dark #input-output #react-file-drop-demo textarea {
    background: #191919; }
  .themed.dark .row-main .row-navigation:before {
    background: #191919; }
  .themed.dark #input-output .col-left .btn {
    color: white !important; }
  #input-output {
    min-height: calc(100vh - 22px);
    height: auto;
    display: block; }
    #input-output div {
      word-break: break-word; }
    #input-output .col {
      display: block;
      height: auto; }
    #input-output .col-left {
      height: auto; }
      #input-output .col-left > div {
        height: auto; }
        #input-output .col-left > div > div, #input-output .col-left > div textarea {
          height: auto; }
      #input-output .col-left #react-file-drop-demo {
        height: auto; }
      #input-output .col-left .input-types > div {
        height: auto; }
        #input-output .col-left .input-types > div > div {
          height: auto; }
    #input-output .col-right {
      height: auto; }
      #input-output .col-right > div {
        height: auto; }
        #input-output .col-right > div > div {
          height: auto; }
  .level-0 #input-output {
    transition: min-height .4s ease-in-out, -webkit-transform .4s ease-in-out;
    transition: min-height .4s ease-in-out, transform .4s ease-in-out;
    transition: min-height .4s ease-in-out, transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  .level-0 #input-output #react-file-drop-demo textarea {
    max-height: calc(100vh - 170px) !important; }
  .level-0.input-focus #input-output #react-file-drop-demo textarea {
    max-height: calc(100vh - 423px) !important; }
  .level-1 .row-main .row-navigation:before,
  .level-2 .row-main .row-navigation:before,
  .level-3 .row-main .row-navigation:before,
  .level-4 .row-main .row-navigation:before,
  .level-5 .row-main .row-navigation:before {
    transition: background .4s ease-out, opacity .2s  ease-in-out, -webkit-transform .4s ease-in-out;
    transition: background .4s ease-out, opacity .2s  ease-in-out, transform .4s ease-in-out;
    transition: background .4s ease-out, opacity .2s  ease-in-out, transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 0; }
  .level-1 .row-navigation > .col,
  .level-2 .row-navigation > .col,
  .level-3 .row-navigation > .col,
  .level-4 .row-navigation > .col,
  .level-5 .row-navigation > .col {
    -webkit-transform: translate3d(0, -60px, 0);
            transform: translate3d(0, -60px, 0); }
    .level-1 .row-navigation > .col:first-child .language-switch,
    .level-2 .row-navigation > .col:first-child .language-switch,
    .level-3 .row-navigation > .col:first-child .language-switch,
    .level-4 .row-navigation > .col:first-child .language-switch,
    .level-5 .row-navigation > .col:first-child .language-switch {
      -webkit-transform: translate3d(15%, 0, 0) scale(0.8);
              transform: translate3d(15%, 0, 0) scale(0.8); }
    .level-1 .row-navigation > .col:nth-child(2) .language-switch,
    .level-2 .row-navigation > .col:nth-child(2) .language-switch,
    .level-3 .row-navigation > .col:nth-child(2) .language-switch,
    .level-4 .row-navigation > .col:nth-child(2) .language-switch,
    .level-5 .row-navigation > .col:nth-child(2) .language-switch {
      -webkit-transform: translate3d(0%, 0, 0) scale(0.8);
              transform: translate3d(0%, 0, 0) scale(0.8); }
  .level-1 #input-output,
  .level-2 #input-output,
  .level-3 #input-output,
  .level-4 #input-output,
  .level-5 #input-output {
    -webkit-transform: translate3d(0, -60px, 0);
            transform: translate3d(0, -60px, 0); }
  .level-1 #input-output #react-file-drop-demo textarea {
    max-height: calc(100vh - 110px) !important; }
  .level-1.input-focus #input-output #react-file-drop-demo textarea {
    max-height: calc(100vh - 363px) !important; }
  .level-1 #wrapper.menuOpen .row-main .row-navigation:before,
  .level-2 #wrapper.menuOpen .row-main .row-navigation:before,
  .level-3 #wrapper.menuOpen .row-main .row-navigation:before,
  .level-4 #wrapper.menuOpen .row-main .row-navigation:before,
  .level-5 #wrapper.menuOpen .row-main .row-navigation:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; }
  .level-1 #wrapper.menuOpen #input-output,
  .level-2 #wrapper.menuOpen #input-output,
  .level-3 #wrapper.menuOpen #input-output,
  .level-4 #wrapper.menuOpen #input-output,
  .level-5 #wrapper.menuOpen #input-output {
    -webkit-transform: translate3d(100%, -60px, 0);
            transform: translate3d(100%, -60px, 0); }
  .level-1 #wrapper.menuOpen .row-navigation > .col,
  .level-2 #wrapper.menuOpen .row-navigation > .col,
  .level-3 #wrapper.menuOpen .row-navigation > .col,
  .level-4 #wrapper.menuOpen .row-navigation > .col,
  .level-5 #wrapper.menuOpen .row-navigation > .col {
    -webkit-transform: translate3d(200%, -60px, 0);
            transform: translate3d(200%, -60px, 0); }
  .level-2 #input-output,
  .level-3 #input-output,
  .level-4 #input-output,
  .level-5 #input-output {
    -webkit-transform: translate3d(0, -100px, 0);
            transform: translate3d(0, -100px, 0); }
  .level-2 #input-output #react-file-drop-demo textarea {
    max-height: calc(100vh - 70px) !important; }
  .level-2.input-focus #input-output #react-file-drop-demo textarea {
    max-height: calc(100vh - 323px) !important; }
  .level-2 #wrapper.menuOpen #input-output,
  .level-3 #wrapper.menuOpen #input-output {
    -webkit-transform: translate3d(100%, -100px, 0);
            transform: translate3d(100%, -100px, 0); }
  .level-3 #input-output #react-file-drop-demo textarea,
  .level-4 #input-output #react-file-drop-demo textarea,
  .level-5 #input-output #react-file-drop-demo textarea {
    height: 100px !important; }
  .level-3 .translationOutputArea,
  .level-4 .translationOutputArea,
  .level-5 .translationOutputArea {
    height: calc(100vh - 210px) !important; }
  .level-3.input-focus .translationOutputArea {
    height: calc(100vh - 463px) !important; }
  .level-4 #input-output,
  .level-5 #input-output {
    -webkit-transform: translate3d(0, -203px, 0);
            transform: translate3d(0, -203px, 0); }
  .level-4 .translationOutputArea,
  .level-5 .translationOutputArea {
    height: calc(100vh - 110px) !important; }
  .level-4.input-focus .translationOutputArea,
  .level-5.input-focus .translationOutputArea {
    height: calc(100vh - 363px) !important; }
  .level-4 #wrapper.menuOpen #input-output,
  .level-5 #wrapper.menuOpen #input-output {
    -webkit-transform: translate3d(100%, -203px, 0);
            transform: translate3d(100%, -203px, 0); } }

@media only screen and (max-device-width: 767px) and (orientation: landscape) {
  #wrapper #menu .menu-wrapper {
    overflow-y: scroll;
    padding-top: 70px; }
    #wrapper #menu .menu-wrapper .menu-content {
      position: relative;
      top: 0; }
  #wrapper #menu nav.meta {
    position: relative;
    bottom: auto;
    margin-top: 20px; }
  #logo-2 {
    bottom: 30px; } }


.switch-container {
	position: relative;
	top: 0; 
	left: 0;
	padding-left: 12px;
}

input[type="checkbox"].switch  {
	position: absolute;
	opacity: 0;
}

input[type="checkbox"].switch + div {
	vertical-align: middle;
	width: 40px;
	height: 20px;
	border-radius: 999px;
	border: 1px solid black;
	background-color: rgba(0,0,0,.15);
	background-color: transparent;
	transition-duration: .4s;
	transition-property: background-color, border-color, box-shadow;
	cursor: pointer;
}

input[type="checkbox"].switch + div span {
	position: absolute;
    font-size: 1.6rem;
    color: white;
    margin-top: 12px;
}

input[type="checkbox"].switch + div span:nth-child( 1 ) {
    margin-left: 15px;
}

input[type="checkbox"].switch + div span:nth-child( 2 ) {
    margin-left: 55px;
}

input[type="checkbox"].switch:checked + div {
	width: 51px;
	background-position: 0 0;
	background-color: rgba(0,0,0,1);/* #EC672F; */
	border-color: rgba(0,0,0,1);
}

input[type="checkbox"].switch + div {
	width: 51px;
	height: 28px;
	position: absolute;
	right: 0;
	top: 10px;
}

input[type="checkbox"].switch + div > div {
	float: left;
	width: 22px; height: 22px;
	border-radius: inherit;
	background-color: black;
	transition-timing-function: cubic-bezier(1,0,0,1);
	transition-duration: 0.4s;
	transition-property: background-color, -webkit-transform;
	transition-property: transform, background-color;
	transition-property: transform, background-color, -webkit-transform;
	pointer-events: none;
	margin-top: 2px;
	margin-left: 2px;
}

input[type="checkbox"].switch:checked + div > div {
	-webkit-transform: translate3d(21px, 0, 0);
	transform: translate3d(21px, 0, 0);
	background-color: white;
}

input[type="checkbox"].bigswitch.switch + div > div {
	width: 22px;
	height: 22px;
	margin-top: 2px;
}


input[type="checkbox"].switch:checked + div > div {
	-webkit-transform: translate3d(23px, 0, 0);
	transform: translate3d(23px, 0, 0);
}

.switch-container {
	margin-left: 0;
	width: 100%;
}

.switch-nob{
  position: relative;
  top: -5px;
}
.switch-nob,
.switch-text{
	display:inline-block;
}
.switch-text{
	margin-left: 4px;
	font-weight: 600;
	font-size: 30px;
}



.themed.dark input[type="checkbox"].switch + div {
	border: 1px solid white;
}

.themed.dark input[type="checkbox"].switch + div > div {
	background-color: white;
}
.themed.dark input[type="checkbox"].switch:checked + div {
	background-color: rgba(255,255,255,1);
	border-color: rgba(255,255,255,1);
}
.themed.dark input[type="checkbox"].switch:checked + div > div {
	background-color: black;
} 
label{
	display: block;	
}
@media (max-width: 767px) {
  .switch-text {
	  font-size: 26px;
    padding-top: 0px;
    line-height: 37px;
    letter-spacing: -0.0033em;
	}
}

.file-drop {
  /* relatively position the container bc the contents are absolute */
  position: relative;
  height: 100%;
  width: 100%;
}

.file-drop > .file-drop-target {
  /* basic styles */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;
  /* --------------- */

  /* horizontally and vertically center all content */
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;

  flex-direction: column;
  -webkit-flex-direction: column;

  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;

  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  align-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;

  text-align: center;
  /* --------------- */
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  /* overlay a black mask when dragging over the frame */
  border: none;
  background-color: rgba(0, 0, 0, 0.65);
  box-shadow: none;
  z-index: 50;
  opacity: 1.0;
  /* --------------- */

  /* typography */
  color: white;
  /* --------------- */
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  /* turn stuff orange when we are dragging over the target */
  color: #ff6e40;
  box-shadow: 0 0 13px 3px #ff6e40;
}

@media (max-width: 767px) {
  .file-drop {
    height: auto;
  }
  .file-drop > .file-drop-target{
    position: relative;
  }
}
.react-simple-keyboard {
  height: unset !important;
  word-break: unset !important;
  position: absolute;
  bottom: 35px;
  z-index: 3; }


#preloader{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #0000002a !important;
    color: black;
    
  }
.circ1, .circ2, .circ3, .circ4 {
    background-color: black !important;
}
