
.switch-container {
	position: relative;
	top: 0; 
	left: 0;
	padding-left: 12px;
}

input[type="checkbox"].switch  {
	position: absolute;
	opacity: 0;
}

input[type="checkbox"].switch + div {
	vertical-align: middle;
	width: 40px;
	height: 20px;
	border-radius: 999px;
	border: 1px solid black;
	background-color: rgba(0,0,0,.15);
	background-color: transparent;
	-webkit-transition-duration: .4s;
	transition-duration: .4s;
	-webkit-transition-property: background-color, border-color, box-shadow;
	transition-property: background-color, border-color, box-shadow;
	cursor: pointer;
}

input[type="checkbox"].switch + div span {
	position: absolute;
    font-size: 1.6rem;
    color: white;
    margin-top: 12px;
}

input[type="checkbox"].switch + div span:nth-child( 1 ) {
    margin-left: 15px;
}

input[type="checkbox"].switch + div span:nth-child( 2 ) {
    margin-left: 55px;
}

input[type="checkbox"].switch:checked + div {
	width: 51px;
	background-position: 0 0;
	background-color: rgba(0,0,0,1);/* #EC672F; */
	border-color: rgba(0,0,0,1);
}

input[type="checkbox"].switch + div {
	width: 51px;
	height: 28px;
	position: absolute;
	right: 0;
	top: 10px;
}

input[type="checkbox"].switch + div > div {
	float: left;
	width: 22px; height: 22px;
	border-radius: inherit;
	background-color: black;
	-webkit-transition-timing-function: cubic-bezier(1,0,0,1);
	transition-timing-function: cubic-bezier(1,0,0,1);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform, background-color;
	transition-property: transform, background-color;
	pointer-events: none;
	margin-top: 2px;
	margin-left: 2px;
}

input[type="checkbox"].switch:checked + div > div {
	-webkit-transform: translate3d(21px, 0, 0);
	transform: translate3d(21px, 0, 0);
	background-color: white;
}

input[type="checkbox"].bigswitch.switch + div > div {
	width: 22px;
	height: 22px;
	margin-top: 2px;
}


input[type="checkbox"].switch:checked + div > div {
	-webkit-transform: translate3d(23px, 0, 0);
	transform: translate3d(23px, 0, 0);
}

.switch-container {
	margin-left: 0;
	width: 100%;
}

.switch-nob{
  position: relative;
  top: -5px;
}
.switch-nob,
.switch-text{
	display:inline-block;
}
.switch-text{
	margin-left: 4px;
	font-weight: 600;
	font-size: 30px;
}



.themed.dark input[type="checkbox"].switch + div {
	border: 1px solid white;
}

.themed.dark input[type="checkbox"].switch + div > div {
	background-color: white;
}
.themed.dark input[type="checkbox"].switch:checked + div {
	background-color: rgba(255,255,255,1);
	border-color: rgba(255,255,255,1);
}
.themed.dark input[type="checkbox"].switch:checked + div > div {
	background-color: black;
} 
label{
	display: block;	
}
@media (max-width: 767px) {
  .switch-text {
	  font-size: 26px;
    padding-top: 0px;
    line-height: 37px;
    letter-spacing: -0.0033em;
	}
}
